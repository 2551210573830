import { Row, Col, Card, Typography } from "antd";
import "./homePage.css";
import { useEffect, useState } from "react";

const ONE_DAY = 1000 * 60 * 60 * 24;
const ONE_WEEK = ONE_DAY * 7;
const ONE_MONTH = ONE_DAY * 30; // Aproximado

export default function HomePage() {
  // Fecha final del contador (8 de septiembre de 2024)
  const deadline = new Date("2024-09-25T00:00:00").getTime();

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    weeks: 0,
    months: 0,
  });

  // Añadido la funcionalidad para determinar el estado del evento
  const now = new Date();

  const getEventStatus = (eventDate: Date): string => {
    const eventYear = eventDate.getFullYear();
    const eventMonth = eventDate.getMonth();

    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    if (
      eventYear < currentYear ||
      (eventYear === currentYear && eventMonth < currentMonth)
    ) {
      return "past";
    }

    if (eventYear === currentYear && eventMonth === currentMonth) {
      return "current";
    }

    if (
      eventYear > currentYear ||
      (eventYear === currentYear && eventMonth > currentMonth)
    ) {
      return "future";
    }

    return "unknown";
  };

  const electionEvents = [
    { date: new Date("2023-10-31"), event: "Definir Plan de Estructuras funsionales de la campaña RR Alcalde \"TIERRA\"" },
    { date: new Date("2024-04-15"), event: "\"Empuje de Imagen RR con propuestas\"" },
    { date: new Date("2024-07-01"), event: "Proselitismo con jóvenes" }, // Asumiendo el inicio de julio
    { date: new Date("2023-12-03"), event: "Anuncio Campaña RR" },
    { date: new Date("2024-06-01"), event: "Proselitismo en calle, inicio" }, // Asumiendo el inicio de junio
    { date: new Date("2023-11-07"), event: "\"Equipo de Campaña\"" },
    { date: new Date("2024-05-26"), event: "Evento de Lanzamiento" },
    { date: new Date("2023-10-22"), event: "Analisis de la huella electoral del DC \"Voto Multiplicador\"" },
    { date: new Date("2024-03-31"), event: "Final Etapa de \"Organización Territorial\" -Zonas -CV -Barrios -Col -Aldeas" },
    { date: new Date("2024-07-06"), event: "Anuncio de Planilla Municipal" },
    { date: new Date("2024-08-29"), event: "Reunión Pública con \"Papi a la Orden\"" },
    { date: new Date("2024-09-01"), event: "negociacion de la planilla" }, // Asumiendo el inicio de septiembre
    { date: new Date("2024-08-01"), event: "Proselitismo con mujeres" }, // Asumiendo el inicio de agosto
    { date: new Date("2024-09-25"), event: "\"Negociación / Aceptación del Cargo\" por \"Papi a la Orden\"" }
  ];  

  electionEvents.sort((a:any, b:any) => a.date - b.date);


  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = deadline - now;

      const days = Math.floor(distance / ONE_DAY);
      const weeks = Math.floor(distance / ONE_WEEK);
      const months = Math.floor(distance / ONE_MONTH);

      setTimeLeft({ days, weeks, months });
    }, 1000);

    return () => clearInterval(timer);
  }, [deadline]);

  return (
    <div className="home-page">
      <img className="banner" src="assets/img/banner.png" alt="Banner" />
      <Row justify="center" align="middle" className="countdown-row">
        <Col span={24}>
          <Card className="countdown-card">
            <Typography.Title level={3}>
              Faltan {timeLeft.months} meses para el día D para RR
            </Typography.Title>
          </Card>
          <Card className="countdown-card">
            <Typography.Title level={3}>
              Faltan {timeLeft.weeks} semanas para el día D para RR
            </Typography.Title>
          </Card>
          <Card className="countdown-card">
            <Typography.Title level={3}>
              Faltan {timeLeft.days} días para el día D para RR
            </Typography.Title>
          </Card>
        </Col>
      </Row>
      <Row className="election-events" justify="center">
        <Col span={24}>
          <Typography.Title level={2}>
            Fechas importantes en el movimiento:
          </Typography.Title>
          <ul>
            {electionEvents.map((event, index) => (
              <li
                key={index}
                className={`event-item ${getEventStatus(event.date)}`}
              >
                <Typography.Text strong>
                  {event.date.toLocaleDateString()}
                </Typography.Text>
                : {event.event}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
}
